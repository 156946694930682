import React, { Component } from 'react';
import { MDBCol, MDBRow, MDBBtn, MDBIcon, MDBTooltip } from 'mdbreact';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './style.css';

class ContactPage extends Component {
	state = {
		collapseID: 'collapse1',
		value1: '(951)852-2584',
		copied1: false,
		copied2: false,
		value2: 'TimWestberg@formulaGen.com'
	};

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ''
		}));

	render() {
		return (
			<MDBRow id='Header' className='text-center'>
				<MDBCol sm='12'>
					<h1 className='white-text h1-responsive font-weight-bold text-center my-5'>Tim Westberg</h1>
				</MDBCol>

				<MDBCol sm='12' md='4' lg='3' className='white-text'>
					<MDBTooltip
						placement='right'
						tag='div'
						tooltipContent={
							<MDBIcon far icon='copy'>
								<br />{' '}
								{this.state.copied1 ? (
									<span className='font-weight-bold' style={{ color: 'white' }}>
										Copied
									</span>
								) : (
									<span className='font-weight-bold' style={{ color: 'white' }}>
										Copy
									</span>
								)}
							</MDBIcon>
						}
						className='d-flex justify-content-center'>
						<CopyToClipboard text={this.state.value1} onCopy={() => this.setState({ copied1: true })}>
							<a className='btn-floating primary-color'>
								<MDBIcon icon='phone' />
							</a>
						</CopyToClipboard>
					</MDBTooltip>
					{'(951)852-2584'.split('').map((letter) => <span className='hvr-float font-weight-bold'>{letter}</span>)}
					<br />
					<MDBTooltip
						placement='right'
						tag='div'
						tooltipContent={
							<MDBIcon far icon='copy'>
								<br />{' '}
								{this.state.copied2 ? (
									<span className='font-weight-bold' style={{ color: 'white' }}>
										Copied
									</span>
								) : (
									<span className='font-weight-bold' style={{ color: 'white' }}>
										Copy
									</span>
								)}
							</MDBIcon>
						}
						className='d-flex justify-content-center'>
						<CopyToClipboard text={this.state.value2} onCopy={() => this.setState({ copied2: true })}>
							<a className='btn-floating primary-color' href='#!'>
								<MDBIcon icon='envelope' />
							</a>
						</CopyToClipboard>
					</MDBTooltip>
					<a href='mailto:timwestberg@formulagen.com'>
						<h6 className='font-weight-bold emailTim'>
							{' '}
							{'TimWestberg@FormulaGen.com'.split('').map((letter) => (
								<span style={{ color: 'white' }} className='hvr-float'>
									{letter}
								</span>
							))}
						</h6>{' '}
					</a>
				</MDBCol>

				<MDBCol sm='12' md='8' lg='6'>
					<p className='white-text text-center introduction'>
						I am a user-focused Web Developer with a passion for integrating technologies to solve problems and
						improve existing methods. My background in office management, bookkeeping, and sales provides me with
						a unique perspective on how end-users interact with websites and software platforms. I enjoy
						approaching programming challenges from different angles and collaborating with others to create
						meaningful web applications that effectively elevate the user and client experience. My ultimate goal
						is to provide solutions that are simple and intuitive to clients.
					</p>
				</MDBCol>

				<MDBCol sm='12' md='4' lg='3' className='white-text'>
					<MDBTooltip
						placement='left'
						tag='div'
						tooltipContent={
							<MDBIcon icon='map-marked-alt'>
								{' '}
								<br />Maps
							</MDBIcon>
						}
						className='d-flex justify-content-center'>
						<a
							className='btn-floating primary-color'
							target='_blank'
							href='https://www.google.com/maps/place/Temecula,+CA/@33.4799573,-117.1303691,10.7z/data=!4m13!1m7!3m6!1s0x80c325a785c9a649:0x7223c333f40c68d8!2sSouthern+California,+CA!3b1!8m2!3d34.9592083!4d-116.419389!3m4!1s0x80db6252f51abe23:0x68bc0e88a03806a8!8m2!3d33.4933073!4d-117.1485901'>
							<MDBIcon icon='map-marker-alt' />
						</a>
					</MDBTooltip>
					{'Southern'.split('').map((letter) => <span className='hvr-float font-weight-bold'>{letter}</span>)}{' '}
					{'California'.split('').map((letter) => <span className='hvr-float font-weight-bold'>{letter}</span>)}
					<br />
					{'United'.split('').map((letter) => <span className='hvr-float font-weight-bold'>{letter}</span>)}{' '}
					{'States'.split('').map((letter) => <span className='hvr-float font-weight-bold'>{letter}</span>)}
				</MDBCol>
			</MDBRow>
		);
	}
}

const LoadingContainer = (props) => <div>Fancy loading container!</div>;

export default GoogleApiWrapper({
	apiKey: 'AIzaSyBBdrrchiVVEcgcVy2ssar7l4Ypzz1-81I',
	LoadingContainer: LoadingContainer
})(ContactPage);
