import React, { Component } from 'react';
import { MDBRow, MDBContainer } from 'mdbreact';
import './index.css';
import Nav from './components/Nav/TopNavigation';
import Projects from '../src/components/ProjectsCollage/';
import Experience from '../src/components/Experience/';
import Contact from '../src/components/Contact';
class App extends Component {
	render() {
		return (
			<div className='App'>
				<MDBRow>
					<Nav />
				</MDBRow>

				<MDBRow id='contact'>
					<Contact />
				</MDBRow>

				<MDBRow id='projects'>
					<Projects />
				</MDBRow>

				<MDBRow id='experience'>
					<Experience />
				</MDBRow>
			</div>
		);
	}
}

export default App;
